import { Box } from '@mui/material';
import { theme } from '@portals/core/src/themes/sde/main';
import { FitToBoundsHandler } from '@portals/maps/src/components/FitToBoundsHandler/FitToBoundsHandler';
import { MapContainer } from '@portals/maps/src/containers/MapContainer/MapContainer';
import { MarkerClusterGroup } from '@portals/maps/src/containers/MarkerClusterGroup/MarkerClusterGroup';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { LatLngExpression } from 'leaflet';
import { filter, map } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MapEstateDialog } from '../MapEstateDialog/MapEstateDialog';
import { MarkerGlobalStyles, ZoomControlGlobalStyles } from './EstateResultMapView.styles';
import { EstateMarkerComponent } from './Marker/EstateMarkerComponent';

interface Props {
  estates?: Array<EstateItem>;
}

const getCoordinatesFromEstates = (estates) =>
  map(estates, ({ lat, lng }) => ({
    lat,
    lng,
  }));

export const EstateResultMapView: React.FunctionComponent<Props> = ({ estates }: Props) => {
  const [filteredClusterEstates, setFilteredClusterEstates] = useState([]);
  const TILE_URL = ConfigProvider.getConfig().get('YELLOWMAP_TILE_URL');

  useEffect(() => {
    hideEstateDetails();
  }, [estates]);

  const coordinates = useMemo<LatLngExpression[]>(() => {
    return getCoordinatesFromEstates(estates);
  }, [estates]);

  const center = coordinates.length > 0 ? coordinates[0] : undefined;

  const findEstatesByBounds = (currentBounds) => {
    return filter(estates, ({ lat, lng }) => {
      return currentBounds.contains({
        lat,
        lng,
      });
    });
  };

  const findEstatesByLatAndLng = (markerLat, markerLng) => {
    return filter(estates, ({ lat, lng }) => {
      return lat === markerLat && lng === markerLng;
    });
  };

  const showFilteredClusterEstate = ({ lat, lng }) => {
    const filteredEstates = findEstatesByLatAndLng(lat, lng);
    setFilteredClusterEstates(filteredEstates);
  };

  const onClickEstateMarker = useCallback(({ latlng }) => {
    showFilteredClusterEstate(latlng);
  }, []);

  const hideEstateDetails = useCallback(() => {
    setFilteredClusterEstates([]);
  }, []);

  const showFilteredClusterEstates = (e) => {
    const parentChildClusterLength = e.propagatedFrom.__parent._childClusters.length;
    const hasNoChildClusters = e.propagatedFrom._childClusters.length === 0;
    if (parentChildClusterLength === 1 && hasNoChildClusters) {
      const currentBounds = e.propagatedFrom.getBounds();
      const filteredEstates = findEstatesByBounds(currentBounds);
      setFilteredClusterEstates(filteredEstates);
    }
  };

  const onClickMarkerClusterGroup = useCallback((e) => {
    showFilteredClusterEstates(e);
  }, []);

  if (estates.length === 0) {
    return null;
  }

  return (
    <Box position="relative" height={1} overflow="hidden">
      <Box position="absolute" left={0} top={0} height={1} width={1}>
        <MapContainer
          className="estate-maps"
          containerProps={{
            width: '100%',
            height: '100%',
          }}
          style={{ width: '100%', height: '100%' }}
          center={center}
          zoom={12}
          minZoom={7}
          maxZoom={18}
          url={TILE_URL}
          zoomControl={true}
          scrollWheelZoom={false}
        >
          <FitToBoundsHandler coordinates={coordinates} padding={12} />
          {MarkerGlobalStyles(theme)}
          {ZoomControlGlobalStyles(theme)}
          <MarkerClusterGroup spiderfyOnMaxZoom={false} showCoverageOnHover={false} onClick={onClickMarkerClusterGroup}>
            {map(estates, (estate) => {
              return (
                <EstateMarkerComponent key={estate.id} estateItem={estate} onClickEstateMarker={onClickEstateMarker} />
              );
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </Box>
      <MapEstateDialog onClose={hideEstateDetails} estates={filteredClusterEstates} />
    </Box>
  );
};

EstateResultMapView.displayName = 'EstateResultMapView';
