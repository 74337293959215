import { SxProps } from '@mui/material/styles';
import { theme } from '@portals/core/src/themes/immobilien/main';

export const getStyles = (): Record<string, SxProps> => ({
  finalContainer: {
    mb: { xs: 0, sm: 0, md: 0, lg: 0 },
    width: '100%',
  },
  headerContainer: {
    mt: { sm: 5, md: 4 },
    display: { xs: 'none', md: 'inherit', text: 'inherit', lg: 'block' },
  },
  estateResultFilterViewContainer: {
    display: { xs: 'none', md: 'inherit', text: 'inherit', lg: 'inherit' },
  },
  resultListControlsWrapper: {
    position: 'fixed',
    top: 0,
    zIndex: 2,
    borderBottom: `1px solid ${theme.palette.border.default}`,
    transition: 'border-bottom-color 0.3s ease-in-out',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.background.default,
    width: '100%',
  },
  resultListControlsFinalContainer: {
    my: { xs: 0, sm: 0, md: 0, lg: 0 },
  },

  mobileSearchResultHeadline: {
    display: { xs: 'inherit', md: 'none', text: 'none', lg: 'none' },
  },
  estateResultTypeToggle: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { xs: 'column', sm: 'row' },
  },
  estateResultSortOrder: {
    display: 'flex',
    justifyContent: 'center',
    pt: { xs: 5, sm: 0 },
    mr: { xs: 'inheriet', md: '50px', lg: 'inherit' },
  },
  customizeSearchButton: {
    alignSelf: 'flex-start',
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'flex-end' },
    width: { xs: '100%', sm: 'auto' },
  },
  customizeSearchButtonSticky: {
    alignSelf: 'flex-start',
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'flex-end' },
    width: { xs: 'auto', sm: 'auto' },
    px: { xs: '30px', sm: '36px', lg: '48px' },
  },
  resultListControlsCustomGridContainer: {
    maxWidth: { md: 'none' },
    flexWrap: { xs: 'wrap', lg: 'initial' },
    mt: 5,
    mb: 6,
    justifyContent: 'space-between',
  },
  estateResultSortOrderSticky: {
    display: { xs: 'none', sm: 'flex' },
    justifyContent: 'center',
    pt: { xs: 5, sm: 0 },
    mr: { xs: 'inherit', md: '50px', lg: 'inherit' },
  },
  estateResultTypeToggleSticky: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  refBox: {
    position: { xs: 'absolute', md: 'relative' },
    top: { xs: '310px', sm: '270px', md: 0 },
  },
});
