import { GlobalStyles, Theme } from '@mui/material';
import React from 'react';

export const MarkerGlobalStyles = (theme: Theme): React.ReactElement => {
  return (
    <GlobalStyles
      styles={{
        '.estate-maps .marker-cluster div': {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: theme.spacing(12),
          height: '38px',
          width: '38px',
          margin: '0',
          fontFamily: 'Sparkasse Medium',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '18px',
          letterSpacing: '-0.26px',
        },

        '.estate-maps .marker-cluster-small, .estate-maps .marker-cluster-medium, .estate-maps .marker-cluster-large': {
          backgroundColor: 'transparent',
        },

        '.estate-maps .marker-cluster-small div, .estate-maps .marker-cluster-medium div, .estate-maps .marker-cluster-large div':
          {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
          },

        '.estate-maps .marker-cluster-large div': {
          width: 'fit-content',
          padding: '9px 18px',
        },

        '.estate-maps .leaflet-marker-icon:not(.marker-cluster)': {
          fontFamily: 'Sparkasse Medium',
          fontSize: '13px',
          lineHeight: '18px',
          letterSpacing: '-0.26px',
          whiteSpace: 'nowrap',
          color: theme.palette.secondary.contrastText,
        },

        '.estate-maps .leaflet-marker-icon:not(.marker-cluster) > div': {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: 'auto !important',
          height: '36px !important',
          borderRadius: theme.spacing(12),
          padding: '9px 18px',
          backgroundColor: theme.palette.secondary.dark,
        },

        '.estate-maps .leaflet-marker-icon:not(.marker-cluster) > div.bookmarked': {
          backgroundColor: theme.palette.primary.main,
        },

        '.estate-maps .icon-bookmarked': {
          width: theme.spacing(5),
          height: theme.spacing(5),
          fill: theme.palette.secondary.contrastText,
        },

        '@media (min-width: 1440px)': {
          '.estate-maps .marker-cluster div': {
            width: '50px',
            height: '50px',
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '-0.24px',
          },

          '.estate-maps .marker-cluster-large div': {
            width: 'fit-content ',
            padding: '12px 25px',
          },

          '.estate-maps .leaflet-marker-icon:not(.marker-cluster)': {
            whiteSpace: 'nowrap',
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '-0.24px',
          },

          '.estate-maps .leaflet-marker-icon:not(.marker-cluster) > div': {
            padding: '12px 24px',
            height: '50px !important',
            backgroundColor: theme.palette.secondary.dark,
          },

          '.estate-maps .icon-bookmarked': {
            width: theme.spacing(6),
            height: theme.spacing(6),
          },
        },
      }}
    />
  );
};

const ZOOM_IN_ICON_URL =
  // eslint-disable-next-line @if6/fi-no-absolute-urls
  'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M15 8.25H9.75V3C9.75 2.80109 9.67098 2.61032 9.53033 2.46967C9.38968 2.32902 9.19891 2.25 9 2.25C8.80109 2.25 8.61032 2.32902 8.46967 2.46967C8.32902 2.61032 8.25 2.80109 8.25 3V8.25H3C2.80109 8.25 2.61032 8.32902 2.46967 8.46967C2.32902 8.61032 2.25 8.80109 2.25 9C2.25 9.19891 2.32902 9.38968 2.46967 9.53033C2.61032 9.67098 2.80109 9.75 3 9.75H8.25V15C8.25 15.1989 8.32902 15.3897 8.46967 15.5303C8.61032 15.671 8.80109 15.75 9 15.75C9.19891 15.75 9.38968 15.671 9.53033 15.5303C9.67098 15.3897 9.75 15.1989 9.75 15V9.75H15C15.1989 9.75 15.3897 9.67098 15.5303 9.53033C15.671 9.38968 15.75 9.19891 15.75 9C15.75 8.80109 15.671 8.61032 15.5303 8.46967C15.3897 8.32902 15.1989 8.25 15 8.25Z" fill="currentColor"/></svg>';

const ZOOM_OUT_ICON_URL =
  // eslint-disable-next-line @if6/fi-no-absolute-urls
  'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M15.75 9.75H2.25C2.0475 9.75 1.86 9.6675 1.7175 9.5325C1.575 9.39 1.5 9.2025 1.5 9C1.5 8.7975 1.5825 8.61 1.7175 8.4675C1.86 8.325 2.0475 8.25 2.25 8.25H15.75C15.9525 8.25 16.14 8.3325 16.2825 8.4675C16.425 8.61 16.5 8.7975 16.5 9C16.5 9.2025 16.4175 9.39 16.2825 9.5325C16.14 9.675 15.9525 9.75 15.75 9.75Z"/></svg>';

export const ZoomControlGlobalStyles = (theme: Theme): React.ReactElement => {
  return (
    <GlobalStyles
      styles={{
        '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar': {
          border: 'none',
        },
        '.leaflet-right .leaflet-control': { marginRight: theme.spacing(5) },
        '.leaflet-top .leaflet-control': { marginTop: theme.spacing(5) },

        '.leaflet-control-zoom': {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(4),
        },

        '.leaflet-touch .leaflet-bar a': {
          width: '42px',
          height: '42px',
          borderRadius: '90px !important',
          boxShadow: `inset 0 0 0 1px ${theme.palette.grey[400]}`,
          border: 'none',
        },

        '.leaflet-control-zoom-in, .leaflet-control-zoom-out': {
          position: 'relative !important',
          '--leaflet-zoom-icon': theme.palette.secondary.dark,
        },

        '.leaflet-control-zoom-in span, .leaflet-control-zoom-out span': {
          display: 'none',
        },

        '.leaflet-control-zoom-in::after': {
          content: '""',
          display: 'inline-block',
          width: theme.spacing(5),
          height: theme.spacing(5),
          background: `var(--leaflet-zoom-icon, ${theme.palette.grey[800]})`,
          mask: `url('${ZOOM_IN_ICON_URL}')`,
          maskSize: 'cover',
          webkitMask: `url('${ZOOM_IN_ICON_URL}')`,
          webkitMaskSize: 'cover',
          marginTop: '12px',
        },

        '.leaflet-control-zoom-out::after': {
          content: '""',
          display: 'inline-block',
          width: theme.spacing(5),
          height: theme.spacing(5),
          background: `var(--leaflet-zoom-icon, ${theme.palette.grey[800]})`,
          mask: `url('${ZOOM_OUT_ICON_URL}')`,
          maskSize: 'cover',
          webkitMask: `url('${ZOOM_OUT_ICON_URL}')`,
          webkitMaskSize: 'cover',
          marginTop: '12px',
        },

        '.leaflet-control-zoom-in.leaflet-disabled, .leaflet-control-zoom-out.leaflet-disabled': {
          '--leaflet-zoom-icon': theme.palette.grey[600],
        },

        '@media (min-width: 1440px)': {
          '.leaflet-right .leaflet-control': { marginRight: '24px' },
          '.leaflet-top .leaflet-control': { marginTop: '24px' },

          '.leaflet-touch .leaflet-bar a': {
            width: '54px',
            height: '54px',
          },

          '.leaflet-control-zoom-in::after': {
            content: '""',
            display: 'inline-block',
            width: theme.spacing(6),
            height: theme.spacing(6),
            background: `var(--leaflet-zoom-icon, ${theme.palette.grey[800]})`,
            mask: `url('${ZOOM_IN_ICON_URL}')`,
            maskSize: 'cover',
            webkitMask: `url('${ZOOM_IN_ICON_URL}')`,
            webkitMaskSize: 'cover',
            marginTop: '14px',
          },

          '.leaflet-control-zoom-out::after': {
            content: '""',
            display: 'inline-block',
            width: theme.spacing(6),
            height: theme.spacing(6),
            background: `var(--leaflet-zoom-icon, ${theme.palette.grey[800]})`,
            mask: `url('${ZOOM_OUT_ICON_URL}')`,
            maskSize: 'cover',
            webkitMask: `url('${ZOOM_OUT_ICON_URL}')`,
            webkitMaskSize: 'cover',
            marginTop: '15px',
          },
        },
      }}
    />
  );
};
