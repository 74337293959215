import { Box, Grid } from '@mui/material';
import { FinalContainer } from '@portals/core/src/components/FinalGrid/FinalGrid';
import React, { useCallback } from 'react';

import { CustomizeSearchButton, EstateResultSortOrder, SearchResultHeadline } from '../../../../components';
import { ResultListTypes } from '../../../../config';
import { EstateResultFilterView } from '../../../EstateResultFilterView/EstateResultFilterView';
import { EstateResultTypeToggle } from '../../../EstateResultFilterView/EstateResultTypeToggle/EstateResultTypeToggle';
import { getStyles } from './FilterAndResultControlView.styles';
import { FilterAndResultControlViewProps } from './FilterAndResultControlView.types';
import { getNumberOfActiveFilters } from './FilterAndResultControlView.utils';
import { StickyHeader } from './StickyHeader';

export const FilterAndResultControlView: React.FC<FilterAndResultControlViewProps> = ({
  searchParams,
  totalItems,
  estates,
  onClickSaveSearch,
  saveSearchButtonLabel,
  filterValues,
  showResultFilterDrawer,
  resultListType,
  onResultListToggleChange,
  onResultListSortChange,
  estateResultFilterViewInView,
  estateResultFilterViewRef,
}: FilterAndResultControlViewProps) => {
  const showResultFilterDrawerCallback = useCallback(
    () => showResultFilterDrawer(true, null),
    [showResultFilterDrawer]
  );
  const isStickyHeader = estateResultFilterViewInView === false;
  const styles = getStyles();

  const buttonContent = (
    <CustomizeSearchButton
      numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
      onClick={showResultFilterDrawerCallback}
      sx={styles.customizeSearchButton}
    />
  );

  return (
    <>
      <FinalContainer sx={styles.finalContainer} rowGap={0}>
        <Box sx={styles.headerContainer}>
          <SearchResultHeadline
            searchParams={searchParams}
            totalItems={totalItems}
            onClickSaveSearch={onClickSaveSearch}
            saveSearchButtonLabel={saveSearchButtonLabel}
            isSaveSearchButtonDisabled={false}
          />
        </Box>
        <Box sx={styles.estateResultFilterViewContainer}>
          <EstateResultFilterView
            filterValues={filterValues}
            numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
            showResultFilterDrawer={showResultFilterDrawer}
          />
        </Box>
      </FinalContainer>
      <Box>
        <Box sx={styles.refBox} ref={estateResultFilterViewRef}></Box>
        <FinalContainer sx={styles.resultListControlsFinalContainer}>
          <Grid container sx={styles.resultListControlsCustomGridContainer}>
            <Grid item xs={12} sx={styles.mobileSearchResultHeadline}>
              <SearchResultHeadline
                searchParams={searchParams}
                totalItems={totalItems}
                onClickSaveSearch={onClickSaveSearch}
                saveSearchButtonLabel={saveSearchButtonLabel}
                isSaveSearchButtonDisabled={false}
                buttonContent={buttonContent}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={7} lg={7}>
              {estates.length > 0 && (
                <Box sx={styles.estateResultTypeToggle}>
                  <EstateResultTypeToggle
                    value={resultListType}
                    onChange={onResultListToggleChange}
                    isStickyHeader={isStickyHeader}
                  />
                  {resultListType === ResultListTypes.LIST && (
                    <Box sx={styles.estateResultSortOrder}>
                      <EstateResultSortOrder
                        value={filterValues.sortBy}
                        onChange={onResultListSortChange}
                        renderValueWithLabel
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </FinalContainer>
      </Box>
      {isStickyHeader && (
        <StickyHeader
          searchParams={searchParams}
          totalItems={totalItems}
          estates={estates}
          onClickSaveSearch={onClickSaveSearch}
          saveSearchButtonLabel={saveSearchButtonLabel}
          filterValues={filterValues}
          showResultFilterDrawer={showResultFilterDrawer}
          estateResultFilterViewInView={estateResultFilterViewInView}
          resultListType={resultListType}
          onResultListToggleChange={onResultListToggleChange}
          onResultListSortChange={onResultListToggleChange}
          estateResultFilterViewRef={estateResultFilterViewRef}
        />
      )}
    </>
  );
};

FilterAndResultControlView.displayName = 'FilterAndResultControlView';
