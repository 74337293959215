import { Box, useTheme } from '@mui/material';
import { SelectField } from '@portals/core/src/components/SelectField/SelectField';
import { SelectFieldItem } from '@portals/core/src/components/SelectFieldItem/SelectFieldItem';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import i18next from 'i18next';
import { map } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { getSortByLabelFromOptionsByValue, getSortByOptions, if6CssPrefix, SortBy } from '../../../config';

interface OptionProps {
  value: SortBy;
  label: string;
}

interface EstateResultSortOrderProps {
  value?: SortBy;
  onChange: (value: SortBy) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  renderValueWithLabel?: boolean;
  fullWidth?: boolean;
  isWrap?: boolean;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateResultSortOrder: React.FC<EstateResultSortOrderProps> = ({
  value,
  onChange,
  variant = 'standard',
  renderValueWithLabel = false,
  fullWidth = false,
  isWrap = false,
}: EstateResultSortOrderProps) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const options: OptionProps[] = getSortByOptions(t);
  const theme = useTheme();
  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSelectedValue(value);
      onChange(value);
    },
    [onChange]
  );

  const renderValue = useCallback(
    (value) => {
      if (renderValueWithLabel) {
        return (
          <Box display="inline-flex" flexWrap={isWrap ? 'wrap' : 'nowrap'}>
            <Typography className="sortOrder-label" variant="button">
              {t('sortOrder')}&nbsp;
            </Typography>
            <Typography className="sortOrder-selected" variant="button" fontWeight="bold">
              {getSortByLabelFromOptionsByValue(t, value)}
            </Typography>
          </Box>
        );
      }

      return getSortByLabelFromOptionsByValue(t, value);
    },
    [renderValueWithLabel, t]
  );

  const menuListProps = {
    MenuListProps: {
      className: 'result-order-menu-list',
    },
  };

  return (
    <SelectField
      className="result-order-select"
      fullWidth={fullWidth}
      variant={variant}
      value={selectedValue}
      onChange={handleChange}
      renderValue={renderValue}
      sx={{
        alignSelf: 'center',
        '& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
          backgroundColor: 'transparent',
        },
        '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          [theme.breakpoints.down('text')]: {
            py: isWrap ? '0' : 3,
          },
        },
        '& .MuiSelect-icon': {
          marginRight: 0,
          right: '-6px',
          padding: '5px',
          bottom: '7px',
        },
        '& .MuiSelect-icon svg': {
          fontSize: '13px',
        },
        '&:before, &:after': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
        '&.Mui-focused:after': {
          borderBottom: 'none',
        },
        '&.result-order-select': {
          marginTop: 2,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          [theme.breakpoints.down('text')]: {
            width: isWrap ? '66%' : 'auto',
            marginTop: 0,
          },
        },
      }}
      MenuProps={{
        className: if6CssPrefix,
        ...menuListProps,
      }}
      id="1"
    >
      {map(options, (option) => (
        <SelectFieldItem disableRipple disableTouchRipple key={option.value} value={option.value} id={option.value}>
          {option.label}
        </SelectFieldItem>
      ))}
    </SelectField>
  );
};

EstateResultSortOrder.displayName = 'EstateResultSortOrder';
