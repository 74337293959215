import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const CurrencyEuro: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M5.77004 14.2401H3.52004L3.91004 12.8001H5.62004C5.61504 12.7131 5.61204 12.6121 5.61204 12.5101C5.61204 12.4081 5.61504 12.3071 5.62004 12.2061L5.61904 12.2201V11.5901C5.61904 11.5681 5.61904 11.5421 5.61904 11.5161C5.61904 11.0521 5.65204 10.5951 5.71504 10.1481L5.70904 10.1991H3.51904L3.90904 8.76909H5.90904C6.47004 5.47409 9.30404 2.99609 12.716 2.99609C12.784 2.99609 12.851 2.99709 12.919 2.99909H12.909C12.924 2.99909 12.943 2.99909 12.961 2.99909C14.423 2.99909 15.812 3.31609 17.061 3.88409L16.999 3.85909L16.299 5.85909C15.346 5.44709 14.239 5.18609 13.077 5.14009L13.059 5.13909C13.002 5.13609 12.936 5.13509 12.869 5.13509C10.706 5.13509 8.90504 6.68909 8.52404 8.74209L8.52004 8.76909H15.22L14.81 10.1991H8.23004C8.17704 10.5941 8.14504 11.0551 8.14004 11.5231V11.5291C8.14004 12.1091 8.14004 12.5291 8.14004 12.7991H13.74L13.33 14.2391H8.33004C8.85004 17.5291 10.73 18.7891 12.99 18.7891C13.008 18.7891 13.03 18.7891 13.052 18.7891C14.204 18.7891 15.307 18.5761 16.323 18.1881L16.26 18.2091L16.98 20.2091C15.749 20.6971 14.323 20.9801 12.831 20.9801C12.778 20.9801 12.725 20.9801 12.672 20.9791H12.68C9.23004 20.9991 6.46004 18.8891 5.77004 14.2391V14.2401Z"
      fill="black"
    />
  </SvgIcon>
);
