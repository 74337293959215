import { AutoCompleteOption, Location } from '../../ApiClientTypes';

export const mapAutocompleteData = (autocompleteResponse: any): Array<AutoCompleteOption> => {
  if (autocompleteResponse?.length > 0) {
    return autocompleteResponse.map((location: Location, i: number) => {
      return {
        label: location.displayCode,
        value: `${location.latitude}/${location.longitude}/${i}`,
      };
    });
  }
  return [];
};
