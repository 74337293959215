import { Box, Grid } from '@mui/material';
import { FinalContainer } from '@portals/core/src/components/FinalGrid/FinalGrid';
import React, { useCallback } from 'react';

import { CustomizeSearchButton, EstateResultSortOrder } from '../../../../components';
import { ResultListTypes } from '../../../../config';
import { EstateResultTypeToggle } from '../../../EstateResultFilterView/EstateResultTypeToggle/EstateResultTypeToggle';
import { getStyles } from './FilterAndResultControlView.styles';
import { FilterAndResultControlViewProps, StickyHeaderProps } from './FilterAndResultControlView.types';
import { getNumberOfActiveFilters } from './FilterAndResultControlView.utils';

export const StickyHeader: React.FC<StickyHeaderProps> = ({
  estates,
  filterValues,
  showResultFilterDrawer,
  resultListType,
  onResultListToggleChange,
  onResultListSortChange,
}: FilterAndResultControlViewProps) => {
  const showResultFilterDrawerCallback = useCallback(
    () => showResultFilterDrawer(true, null),
    [showResultFilterDrawer]
  );
  const styles = getStyles();

  return (
    <Box sx={styles.resultListControlsWrapper}>
      <FinalContainer sx={styles.resultListControlsFinalContainer}>
        <Grid container sx={styles.resultListControlsCustomGridContainer}>
          <Grid item xs={4} sm={8} md={7} lg={7}>
            {estates.length > 0 && (
              <Box sx={styles.estateResultTypeToggleSticky}>
                <EstateResultTypeToggle
                  value={resultListType}
                  onChange={onResultListToggleChange}
                  isStickyHeader={true}
                />
                {resultListType === ResultListTypes.LIST && (
                  <Box sx={styles.estateResultSortOrderSticky}>
                    <EstateResultSortOrder
                      value={filterValues.sortBy}
                      onChange={onResultListSortChange}
                      renderValueWithLabel
                      isWrap
                    />
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          <Grid item>
            <CustomizeSearchButton
              numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
              onClick={showResultFilterDrawerCallback}
              sx={styles.customizeSearchButtonSticky}
            />
          </Grid>
        </Grid>
      </FinalContainer>
    </Box>
  );
};

StickyHeader.displayName = 'StickyHeader';
